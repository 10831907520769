import { AssetType, ObservationSchema } from '@ynomia/core/dist/blueprint';
import {
  getAssetManagerFilters,
  getAssetTypes,
  getAssetsArray,
  getAssetsKeyedByTypes,
} from '.';

import { DEFAULT_STATUS_COLOR } from '../config/constants';
import { createSelector } from 'reselect';

export const getSelectedAssetType = createSelector(
  [getAssetManagerFilters, getAssetTypes],
  (assetManagerFilters, assetTypes) => {
    const { typeFilter } = assetManagerFilters;
    return (assetTypes.find(({ type }) => type === typeFilter) || assetTypes[0]) as AssetType;
  },
);

export const getSuppliersByTypeFilters = createSelector(
  [getAssetManagerFilters, getAssetsKeyedByTypes],
  (assetManagerFilters, assetsKeyedByTypes) => {
    const { typeFilter } = assetManagerFilters;

    if (!typeFilter || !assetsKeyedByTypes) {
      return [];
    }
    const suppliersByTypeFilter = assetsKeyedByTypes.get(typeFilter);
    const supplierListFromAsset = new Map(
      suppliersByTypeFilter?.map(asset => [asset.fields?.supplier, asset]),
    );

    const supplierKeys = [...supplierListFromAsset.keys()];

    if (supplierKeys[0] === 'undefined' && supplierKeys.length === 1) {
      return [];
    }

    return supplierKeys as Array<string>;
  },
);

export const getSuppliersOptions = createSelector(
  [
    getAssetsArray,
    getSelectedAssetType,
  ],
  (
    assetsArray,
    selectedAssetType,
  ) => {
    const fields = selectedAssetType?.fields || [];
    let suppliersOptions:Array <{
      label: string,
      value: string,
    }>  = [];

    const supplierListFromAsset = new Map(
      assetsArray?.map(asset => [`${asset.fields?.supplier || ''}`, asset]),
    );

    const supplierField = fields?.find(field => field.id === 'supplier');
    if (supplierField) {
      suppliersOptions = supplierField?.properties?.options as Array<{
        label: string,
        value: string,
      }> || [];
    }

    const hasAssetWithoutSupplier = supplierListFromAsset.get('');
    if (hasAssetWithoutSupplier) {
      const blankOption = {
        label: !suppliersOptions.length &&  selectedAssetType ?
          selectedAssetType.name : '(blank)',
        value: '',
      };
      suppliersOptions = [...suppliersOptions, blankOption];
    }

    return suppliersOptions;
  },
);

export const getObservationTypes = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    return observationTypes;
  },
);

export const getObservationTypesKeyedById = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const observationTypes = selectedAssetType?.observations || [];
    const observationTypesKeyedById = new Map();

    observationTypes.forEach((type) => {
      observationTypesKeyedById.set(type.id, type);
    });

    return observationTypesKeyedById as Map<string, ObservationSchema>;
  },
);

export const getSelectedAssetTypeStatuses = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const statuses = selectedAssetType?.statuses || [];
    return statuses;
  },
);

export const getColorScheme = createSelector(
  [getSelectedAssetType],
  (selectedAssetType) => {
    const assetStatuses = selectedAssetType?.statuses || [];
    return assetStatuses.reduce((acc, { label, color }) => ({
      ...acc,
      [label]: color || DEFAULT_STATUS_COLOR,
    }), {});
  },
);
