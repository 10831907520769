
import {
  ACCESS_TOKEN_STORAGE_KEY,
  EXPIRED,
  REDIRECT_TO, REGION, USER_INITIATED,
} from '../../config/constants';
import { Button, Dropdown, MenuProps } from 'antd';
import { Navigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  Region, currentRegion, hostnameOptions,
} from '../../config';
import YnomiaIconLight from '../../images/ynomia-icon-light.svg?react';
import { notification } from '../../antdProvider';
import style from './styles.module.less';
import { useAuth0 } from '@auth0/auth0-react';

const LoginView: React.FC = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  const { loginWithRedirect } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();

  const loginHandler = async () => {
    const redirectTo = searchParams.get(REDIRECT_TO) || '';
    await loginWithRedirect({
      authorizationParams: {
        redirectUri: `${window.location.origin}/auth0-callback`,
      },
      appState: {
        [REDIRECT_TO]: redirectTo,
      },
    });
  };

  const regionSelectHandler = (region: Region) => {
    localStorage.setItem(REGION, region);
    window.location.reload();
  };

  // const regionMap = keyBy(hostnameOptions, 'region');
  const regionMap = new Map(hostnameOptions.map(option => [option.region, option]));

  const handleUnauthenticatedReason = () => {
    const unauthenticatedReasonKey = 'unauthenticatedReason';
    if (searchParams.has(unauthenticatedReasonKey)) {
      const unauthenticatedReasonParam = searchParams.get(unauthenticatedReasonKey);
      switch (unauthenticatedReasonParam) {
        case EXPIRED:
          notification.warning({
            message: 'Session Expired',
            description: 'Enter your credentials to log into Ynomia.',
          });
          break;
        case USER_INITIATED:
          notification.info({
            message: 'Logged Out',
            description: 'You have been successfully logged out.',
          });
          break;
      }
      searchParams.delete(unauthenticatedReasonKey);
      setSearchParams(searchParams);
    }
  };

  const onClick: MenuProps['onClick'] = (e) => {
    return regionSelectHandler(e.key as Region);
  };

  const items: MenuProps['items'] = hostnameOptions.map(item => (
    { label: item.label, key: item.region }
  ));

  useEffect(() => {
    handleUnauthenticatedReason();
  }, []);

  if (accessToken) return <Navigate to={'/'} replace />;

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div>
          <YnomiaIconLight className={style.logo} />
        </div>
        <div className={style.logoName}>YNOMIA</div>
        <div className={style.logoDesc}>REAL-TIME CONSTRUCTION</div>
        <Button
          id="login-button"
          type="primary"
          size="large"
          className={style.loginBtn}
          htmlType="submit"
          onClick={loginHandler}
        >
          Log In
        </Button>
        <a href="/forgot-password" className={style.forgotPwdBtn}>Forgot password?</a>

      </div>
      <Dropdown menu={{ onClick, items }} trigger={['click']}>
        <a onClick={e => e.preventDefault()} className={style.regionButton}>
          {regionMap.get(currentRegion)!.label}
        </a>
      </Dropdown>
    </div>
  );
};

export default LoginView;
