import { ContextDispatch, PageLayout } from '../config/types';
import React, { createContext, useContext, useReducer } from 'react';
import LayoutReducer from '../reducers/layout';


export type LayoutStateType = {
  pageLayout: PageLayout
  summariesCollapsed: boolean
  assetDetailsAssetId: string | null
  assetDetailsLayout: PageLayout
};

const urlParams = new URLSearchParams(window.location.search);

const defaultParams = {
  pageLayout: 'assetTable',
  summariesCollapsed: 'false',
  assetDetailsLayout: 'assetTable',
};

const getLayoutParam = (key: string) => {
  return urlParams.get(key) ?? defaultParams[key];
};

const setLayoutPram = (url: URL, key: string, value: string) => (value === defaultParams[key])
  ? url.searchParams.delete(key)
  : url.searchParams.set(key, value);

const initialState: LayoutStateType = {
  pageLayout: getLayoutParam('pageLayout') as PageLayout,
  summariesCollapsed: (getLayoutParam('summariesCollapsed') === 'true'),
  assetDetailsAssetId: null,
  assetDetailsLayout: getLayoutParam('assetDetailsLayout'),
};

const setLayoutSearchParams = (state: LayoutStateType) => {
  const url = new URL(window.location.href);
  setLayoutPram(url, 'pageLayout', state.pageLayout);
  setLayoutPram(url, 'summariesCollapsed', state.summariesCollapsed.toString());
  setLayoutPram(url, 'assetDetailsLayout', state.assetDetailsLayout.toString());
  history.pushState(null, '', url);
};

export const LayoutContext = createContext({
  state: initialState,
  dispatch: (() => {}) as ContextDispatch,
});

export const useLayout = (): [LayoutStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(LayoutContext);
  return [state, dispatch];
};

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LayoutReducer, initialState);

  setLayoutSearchParams(state);
  return (
    <LayoutContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
