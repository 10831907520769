import {
  AccessRuleStateType,
  AssetManagerStateType,
  AssetStateType,
  AssetUpdateStateType,
  ClientCacheStateType,
  ConfigurationStateType,
  DeviceStateType,
  DigitalTwinStateType,
  FeatureStateType,
  LayerStateType,
  LayoutStateType,
  StatusUpdateStateType,
  VisibilityFilterStateType,
  useAccessRule,
  useAsset,
  useAssetManager,
  useAssetUpdate,
  useClientCache,
  useConfiguration,
  useDevice,
  useDigitalTwin,
  useFeature,
  useFile,
  useLayer,
  useLayout,
  useStatusUpdate,
  useUser,
  useVisibilityFilter,
} from '.';
import { ContextDispatch } from '../config/types';

export type ContextStores = {
  clientCacheState: ClientCacheStateType
  layerState: LayerStateType
  assetManagerState: AssetManagerStateType
  visibilityFilterState: VisibilityFilterStateType
  assetUpdateState: AssetUpdateStateType
  statusUpdateState: StatusUpdateStateType
  assetState: AssetStateType
  deviceState: DeviceStateType
  accessRuleState: AccessRuleStateType
  digitalTwinState: DigitalTwinStateType
  configurationState: ConfigurationStateType
  featureState: FeatureStateType
  layoutState: LayoutStateType
  clientCacheDispatch: ContextDispatch
  layerDispatch: ContextDispatch
  userDispatch: ContextDispatch
  assetDispatch: ContextDispatch
  assetManagerDispatch: ContextDispatch
  accessRuleDispatch: ContextDispatch
  fileDispatch: ContextDispatch
  assetUpdateDispatch: ContextDispatch
  statusUpdateDispatch: ContextDispatch
  visibilityFilterDispatch: ContextDispatch
  digitalTwinDispatch: ContextDispatch
  configurationDispatch: ContextDispatch,
  layoutDispatch: ContextDispatch,
};

export const getContextStores = () => ({
  get clientCacheState() {
    const [clientCacheState] = useClientCache();
    return clientCacheState;
  },
  get layerState() {
    const [layerState] = useLayer();
    return layerState;
  },
  get userState() {
    const [userState] = useUser();
    return userState;
  },
  get deviceState() {
    const [deviceState] = useDevice();
    return deviceState;
  },
  get assetManagerState() {
    const [assetManagerState] = useAssetManager();
    return assetManagerState;
  },
  get assetState() {
    const [assetState] = useAsset();
    return assetState;
  },
  get accessRuleState() {
    const [accessRuleState] = useAccessRule();
    return accessRuleState;
  },
  get fileState() {
    const [fileState] = useFile();
    return fileState;
  },
  get assetUpdateState() {
    const [assetUpdateState] = useAssetUpdate();
    return assetUpdateState;
  },
  get statusUpdateState() {
    const [statusUpdateState] = useStatusUpdate();
    return statusUpdateState;
  },
  get visibilityFilterState() {
    const [visibilityFilterState] = useVisibilityFilter();
    return visibilityFilterState;
  },
  get digitalTwinState() {
    const [digitalTwinState] = useDigitalTwin();
    return digitalTwinState;
  },
  get layoutState() {
    const [layoutState] = useLayout();
    return layoutState;
  },
  get configurationState() {
    const [configurationState] = useConfiguration();
    return configurationState;
  },
  get featureState() {
    const [featureState] = useFeature();
    return featureState;
  },
  get clientCacheDispatch() {
    const [, clientCacheDispatch] = useClientCache();
    return clientCacheDispatch;
  },
  get layerDispatch() {
    const [, layerDispatch] = useLayer();
    return layerDispatch;
  },
  get deviceDispatch() {
    const [, deviceDispatch] = useDevice();
    return deviceDispatch;
  },
  get userDispatch() {
    const [, userDispatch] = useUser();
    return userDispatch;
  },
  get assetManagerDispatch() {
    const [, assetManagerDispatch] = useAssetManager();
    return assetManagerDispatch;
  },
  get assetDispatch() {
    const [, assetDispatch] = useAsset();
    return assetDispatch;
  },
  get accessRuleDispatch() {
    const [, accessRuleDispatch] = useAccessRule();
    return accessRuleDispatch;
  },
  get fileDispatch() {
    const [, fileDispatch] = useFile();
    return fileDispatch;
  },
  get assetUpdateDispatch() {
    const [, assetUpdateDispatch] = useAssetUpdate();
    return assetUpdateDispatch;
  },
  get statusUpdateDispatch() {
    const [, statusUpdateDispatch] = useStatusUpdate();
    return statusUpdateDispatch;
  },
  get digitalTwinDispatch() {
    const [, digitalTwinDispatch] = useDigitalTwin();
    return digitalTwinDispatch;
  },
  get visibilityFilterDispatch() {
    const [, visibilityFilterDispatch] = useVisibilityFilter();
    return visibilityFilterDispatch;
  },
  get layoutDispatch() {
    const [, layoutDispatch] = useLayout();
    return layoutDispatch;
  },
  get configurationDispatch() {
    const [, configurationDispatch] = useConfiguration();
    return configurationDispatch;
  },
});
