import { ClientCacheStateType } from '../context';
import { ContextDispatchParam } from '../config/types';
import { SET_CLIENT_CACHE } from '../actions/actionTypes';

export default (
  state: ClientCacheStateType,
  action: ContextDispatchParam,
): ClientCacheStateType => {
  switch (action.type) {
    case SET_CLIENT_CACHE:
      return {
        ...state,
        clientCache: action.payload,
      };
    case 'REMOVE_CLIENT_CACHE':
      return {
        clientCache: null,
      };
    default:
      return state;
  }
};
