import {
  SET_IS_LOADING_PERCENTAGE,
  SET_IS_TWIN_LOADING,
  SET_IS_TWIN_READY,
  SET_LAST_SELECTED_LAYER,
} from './actionTypes';
import { ContextDispatch } from '../config/types';

export const setIsTwinReady = async (
  isTwinReady: boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_TWIN_READY,
    payload: isTwinReady,
  });
};

export const setIsTwinLoading = async (
  isTwinLoading: boolean,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_TWIN_LOADING,
    payload: isTwinLoading,
  });
};

export const setLastSelectedLayer = async (
  lastSelectedLayer: string | undefined,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_LAST_SELECTED_LAYER,
    payload: lastSelectedLayer,
  });
};

export const setIsLoadingPercentage = (
  loadingPercentage: number,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_IS_LOADING_PERCENTAGE,
    payload: loadingPercentage,
  });
};
