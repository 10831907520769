import {
  SET_ASSET_DETAILS_ID,
  SET_ASSET_DETAILS_LAYOUT,
  SET_PAGE_LAYOUT,
  SET_SUMMARIES_COLLAPSED,
} from '../actions/actionTypes';
import { ContextDispatchParam } from '../config/types';
import { LayoutStateType } from '../context';

export default (
  state: LayoutStateType,
  action: ContextDispatchParam,
): LayoutStateType => {
  switch (action.type) {
    case SET_PAGE_LAYOUT:
      return {
        ...state,
        pageLayout: action.payload,
      };
    case SET_ASSET_DETAILS_LAYOUT:
      return {
        ...state,
        assetDetailsLayout: action.payload,
      };
    case SET_SUMMARIES_COLLAPSED:
      if (action.payload) {
        return {
          ...state,
          summariesCollapsed: action.payload,
        };
      }
      return {
        ...state,
        summariesCollapsed: action.payload,
        assetDetailsAssetId: null,
      };
    case SET_ASSET_DETAILS_ID:
      if (action.payload) {
        return {
          ...state,
          assetDetailsAssetId: action.payload,
        };
      }
      return {
        ...state,
        assetDetailsAssetId: action.payload,
      };
    default:
      return state;
  }
};
