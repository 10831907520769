import { Bootstrap } from '@ynomia/core/dist/blueprint';
import { PROJECT_ID_STORAGE_KEY } from '../config/constants';
import client from './Client';

export async function bootstrap(
  newProjectId?: string,
  config: {
    force?: boolean,
    models?: boolean,
  } = {},
): Promise<Bootstrap> {
  const localStorageProjectId = localStorage.getItem(PROJECT_ID_STORAGE_KEY);
  const project = newProjectId || localStorageProjectId || undefined;
  const res = await client.server.bootstrap(
    project,
    {
      // @todo delete me when models is split out from project blueprints
      models: true,
      // But also override config with the config passed into this function:
      ...config,
    },
  );
  return res;
}
