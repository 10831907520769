import { Asset, AssetTableColumn, Layer } from '../config/types';
import { Device, Type } from '@ynomia/core/dist/device';
import { getLayer, getLoadNumber } from '.';
import { AssetStatus } from '@ynomia/core/dist/blueprint';
import { AssetType } from '@ynomia/core/dist/blueprint';
import { DEFAULT_PRETTY_MAC_ADDR_TRUNCATION } from '../config/constants';
import { Dayjs } from 'dayjs';
import { truncateMacAddress } from '@ynomia/core/dist/utils';

export const isFilteredByDateFilter = (updated: Date, dateFilter: [Dayjs, Dayjs]) => {
  /**
   * Originally, we converted all dates to Dayjs to do the date comparison.
   * However, this came with a large performance hit.
   * Thus we changed it to compare using their unix epoch value.
   * Make sure the dateFilter has .startsWith('day') and .endsWith('day') precomputed.
   */
  const [start, end] = dateFilter;
  const res = start.valueOf() <= updated.getTime()
  && updated.getTime() <= end.valueOf();
  return res;
};

export const moveObjectToLast = (array: Array<Object>, obj: Object) => {
  if (array.includes(obj)) {
    const fromIndex = array.indexOf(obj);
    const element = array.splice(fromIndex, 1)[0];
    const toIndex = array.length;

    array.splice(toIndex, 0, element);
    return array;
  }
  return array;
};

export const getAssetsKeyedByStatus = (assetTypes: Array<AssetType>, assetsArray: Array<Asset>) => {

  const assetKeyedByStatuses = new Map();

  if (assetTypes && assetsArray?.length) {
    const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();

    statusesArray?.forEach(({ id }) => {
      const assetsFilteredByStatusId = assetsArray.filter(({ status }) => status === id);
      assetKeyedByStatuses.set(id, assetsFilteredByStatusId);
    });
  }

  return assetKeyedByStatuses as Map<string, Array<Asset>>;
};

export const getAssetDataSource = (
  assets: Asset[],
  customColumns: AssetTableColumn[],
  layersKeyedById: Map<string, Layer>,
  assetsKeyedById: Map<string, Asset>,
  assetStatusesKeyedById: Map<string, AssetStatus>,
  linkedDevicesKeyedByAssetId: Map<string | undefined, Device>,
) => {
  return assets.map(
    (asset, index) => {
      const { id, fields, status, destination, slots } = asset;
      const { parents } = slots || {};
      const layerID = destination?.layerID || null;
      const statusOrder = (status && assetStatusesKeyedById.get(status)?.order) || 0;

      let customFields = {};
      const customColumnsKeyedByKey = new Map(customColumns.map(column => [column.key, column]));
      const customSortFields = {
        'status': statusOrder,
      };

      customColumns.forEach(({ key, custom, layerType, parentSlot })=> {
        switch (custom) {
          case 'matchLayerType':
            customFields[key] = getLayer(layersKeyedById, layerID, layerType);
            break;
          case 'parentSlot':
            customFields[key] = getLoadNumber(assetsKeyedById, fields, key, parents, parentSlot);
            break;
          case 'tagLink':
            const device = linkedDevicesKeyedByAssetId.get(id) || undefined;
            const { addr, type, foreignID } = device || {};
            if (!device) customFields[key] = undefined;
            else if (device && type === Type.MINEW) customFields[key] = foreignID;
            else {
              customFields[key] = truncateMacAddress(
                addr,
                DEFAULT_PRETTY_MAC_ADDR_TRUNCATION,
                false,
              );
            }
            break;
          default:
        }
      });

      const assetStatus = (status && assetStatusesKeyedById.get(status)) || ({} as AssetStatus);

      return {
        ...asset,
        ...fields,
        ...customFields,
        ...destination,
        key: id || index,
        status: assetStatus?.label || '',
        color: assetStatus?.color,
        statusOrder,
        customColumnsKeyedByKey,
        customSortFields,
      };
    },
  ).sort((a, b) => String(a.label).localeCompare(String(b.label)));
};