import React, { FC, useEffect } from 'react';
import { analytics, getDecoratedPath } from '../../../services';
import { getProject, getProjectId } from '../../../selectors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PROJECT_ID_STORAGE_KEY } from '../../../config/constants';
import { generateProjectSlug } from '../../../utils/url';
import { getContextStores } from '../../../context';

/**
 * This component must be under BrowserRouter to track all the page changes.
 */
const RouteTracker: FC = () => {
  const location = useLocation();
  const contextStores = getContextStores();
  const projectId = getProjectId(contextStores);
  const project = getProject(contextStores);
  const { projectSlug } = useParams();
  const history = useNavigate();

  useEffect(() => {
    // If the current path has a project slug, and the user is logged in, correct it.
    if (!projectSlug || !project.metadata) return;
    const { id, name } = project.metadata;
    // Backup the project id in local storage so the site restores
    // the user's last project when they enter the website on the base url.
    localStorage.setItem(PROJECT_ID_STORAGE_KEY, id);
    const newProjectSlug = generateProjectSlug(name, id);
    const newPath = getDecoratedPath({ projectSlug: newProjectSlug });
    if (!newPath) return;
    if (newPath === location.pathname) return;
    history(`${newPath}${location.search}`, { replace: true });
  }, [projectId, location]);

  useEffect(() => {
    if (['/redirect', '/auth0-callback'].includes(location.pathname)) {
      // Do not log redirects
      return;
    }
    analytics.page(location.pathname);
  }, [location]);

  return (
    <></>
  );
};
export default RouteTracker;
