import { SET_DEVICES, SET_FETCHING_DEVICES } from '../actions/actionTypes';
import { ContextDispatchParam } from '../config/types';
import { Device } from '@ynomia/core/dist/device';
import { DeviceStateType } from '../context';

export default (
  state: DeviceStateType,
  action: ContextDispatchParam,
): DeviceStateType => {
  switch (action.type) {
    case SET_DEVICES:
      if (action.payload?.size === 0) return state;
      const devicesKeyedById = new Map<string, Device>([
        ...state.devicesKeyedById,
        ...action.payload,
      ]);

      return {
        ...state,
        devicesKeyedById,
      };
    case SET_FETCHING_DEVICES:
      return {
        ...state,
        isFetchingDevices: action.payload,
      };
    default:
      return state;
  }
};
