import client from './Client';

export async function updateStatuses(
  csv: string,
  tenant: string,
  projectCode: string,
  action: 'plan' | 'apply',
) {
  try {
    const result = await client.server.post(
      `/scratch/project/${tenant}/${projectCode}/update/statuses?`
      + `action=${action}`,
      { csv },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}

export async function batchUpdateAssetsStatus(
  tenant: string,
  projectCode: string,
  assetIds: Array<string>,
  statusId: string,
  date?: Date,
  isBackdated?: boolean,
) {

  const operations = assetIds.map(assetId => ({
    method: 'POST',
    path: '/scratch/telemetry/action',
    body: {
      action: 'STATUS_CHANGE',
      date: date || new Date(),
      isBackdated,
      tenant,
      project: projectCode,
      assetID: assetId,
      target: statusId,
    },
    key: assetId,
  }));

  try {
    const result = await client.server.post(
      '/batch',
      {
        passThroughHeaders: ['*'],
        operations,
      },
    );
    return result.data.value;
  } catch (error) {
    throw error;
  }
}