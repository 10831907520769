import { SET_FETCHING_USER, SET_USER } from '../actions/actionTypes';
import { ContextDispatchParam } from '../config/types';
import { UserStateType } from '../context';

export default (
  state: UserStateType,
  action: ContextDispatchParam,
): UserStateType => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_FETCHING_USER:
      return {
        ...state,
        isFetchingUser: action.payload,
      };
    default:
      return state;
  }
};
