import { Asset, TwinData } from '../config/types';
import {
  getAssetsArray,
  getAssetsKeyedByLayers,
  getFilteredAssetsKeyedByStatusForDigitalTwin,
  getPrimaryLayers,
  getSelectedAssetType,
  getShowOnlySelectedAssets,
} from '.';
import { createSelector } from 'reselect';
import map from './map';

export const getTwinIdsKeyedByAssetId = createSelector(
  [map.assetsKeyedById, getSelectedAssetType, getAssetsArray],
  (assetsKeyedById, selectedAssetType, assetArray) => {
    const { slots, modelIdsSlotIdReference } = selectedAssetType || {};

    if (modelIdsSlotIdReference) {
      const slotsKeyById = new Map(slots?.map(slot => [slot.id, slot]));
      const currentSlot = slotsKeyById.get(modelIdsSlotIdReference);
      const relationship = currentSlot?.relationship;

      const twinArray = assetArray
        .map((asset) => {
          const { id } = asset;
          const assetSlots =  asset?.slots || {};
          const slot =  assetSlots[relationship === 'has_many' ? 'children' : 'parents'];
          const assetIdsFromSlot = slot?.[modelIdsSlotIdReference];
          const twinFromSlot = typeof assetIdsFromSlot === 'string'
            ? [assetIdsFromSlot]
            : assetIdsFromSlot
              ?.map(assetId => assetsKeyedById.get(assetId)?.destination?.twinID || [])
              ?.flat();
          return { id, twinID: twinFromSlot };
        })
        .filter(({ twinID }) => twinID);

      return new Map(twinArray.map(twin => [twin.id, twin]));
    }

    const twinArray = assetArray
      .map((asset) => {
        const { destination, id } = asset;
        const twinID = destination?.twinID;
        return { id, twinID };
      })
      .filter(({ twinID }) => twinID);
    return new Map(twinArray.map(twin => [twin.id, twin]));
  },
);

export const getFilteredTwinData = createSelector(
  [
    getPrimaryLayers,
    getAssetsKeyedByLayers,
    getSelectedAssetType,
    getFilteredAssetsKeyedByStatusForDigitalTwin,
    getTwinIdsKeyedByAssetId,
    map.lastFetchStartTime,
  ],
  (
    primaryLayers,
    assetsKeyedByLayers,
    selectedAssetType,
    filteredAssetsKeyedByStatus,
    twinIdsKeyedByAssetId,
    lastFetchStartTime,
  ) => {
    if (!primaryLayers || !assetsKeyedByLayers || !filteredAssetsKeyedByStatus) {
      return {} as TwinData;
    }
    const { statuses } = selectedAssetType;
    const statusIndex = {};
    statuses?.forEach(({ id, label }) => {
      const assets = filteredAssetsKeyedByStatus.get(id);
      statusIndex[label] = {
        name: label,
        assets: assets
          ?.map((asset) => {
            return twinIdsKeyedByAssetId.get(asset.id!)?.twinID || [];
          })
          ?.flat(),
        count: assets?.length,
      };
    });

    const indexedByLevel = {};
    primaryLayers.forEach(({ id, label }) => {
      const assets = assetsKeyedByLayers.get(id);
      indexedByLevel[label] = {
        name: label,
        count: assets?.length,
        typeIndex: {},
        assets: assets
          ?.map((asset) => {
            return twinIdsKeyedByAssetId.get(asset.id) || [];
          })
          ?.flat(),
        statusIndex,
      };
    });

    return {
      indexedByLevel: indexedByLevel,
      lastFetched: lastFetchStartTime,
    } as TwinData;
  },
);

export const getAssetDetailsTwinData = createSelector(
  [
    getPrimaryLayers,
    getAssetsKeyedByLayers,
    getSelectedAssetType,
    getTwinIdsKeyedByAssetId,
    map.assetDetailsAssetId,
    map.assetsKeyedById,
    map.lastFetchStartTime,
  ],
  (
    primaryLayers,
    assetsKeyedByLayers,
    selectedAssetType,
    twinIdsKeyedByAssetId,
    assetDetailsAssetId,
    assetsKeyedById,
    lastFetchStartTime,
  ) => {
    if (!primaryLayers || !assetsKeyedByLayers || !assetDetailsAssetId) {
      return {} as TwinData;
    }

    const asset = assetsKeyedById.get(assetDetailsAssetId) || {} as Asset;
    const { statuses } = selectedAssetType;
    const statusIndex = {};
    statuses?.forEach(({ label, id }) => {

      if (id && asset.status === id) {
        statusIndex[label] = {
          name: label,
          assets: [
            asset.destination?.twinID,
          ],
          count: 1,
        };
      } else {
        statusIndex[label] = {
          name: label,
          assets: [],
          count: 0,
        };
      }
    });

    const indexedByLevel = {};
    primaryLayers.forEach(({ id, label }) => {
      const assets = assetsKeyedByLayers.get(id);
      indexedByLevel[label] = {
        name: label,
        count: assets?.length,
        typeIndex: {},
        assets: assets
          ?.map((a) => {
            return twinIdsKeyedByAssetId.get(a.id) || [];
          })
          ?.flat(),
        statusIndex,
      };
    });

    return {
      indexedByLevel: indexedByLevel,
      lastFetched: lastFetchStartTime,
    } as TwinData;
  },
);

export const getSelectedTwinIds = createSelector(
  [getShowOnlySelectedAssets, getTwinIdsKeyedByAssetId],
  (selectedAssets, twinIdsKeyedByAssetId) => {
    return selectedAssets
      .map(selected =>
        twinIdsKeyedByAssetId.get(selected.id)?.twinID?.[0],
      )
      .filter(twin => !!twin) as Array<string>;
  },
);