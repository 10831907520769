import { ContextDispatch, PageLayout } from '../config/types';
import {
  SET_ASSET_DETAILS_ID,
  SET_ASSET_DETAILS_LAYOUT,
  SET_PAGE_LAYOUT,
  SET_SUMMARIES_COLLAPSED,
} from './actionTypes';

export const setPageLayout = (
  pageLayout: PageLayout,
  dispatch: ContextDispatch,
) => {
  localStorage.setItem(SET_PAGE_LAYOUT, pageLayout);
  dispatch({
    type: SET_PAGE_LAYOUT,
    payload: pageLayout,
  });
};

export const setAssetDetailsLayout = (
  assetDetaillayout: PageLayout,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_ASSET_DETAILS_LAYOUT,
    payload: assetDetaillayout,
  });
};

export const setSummariesCollapsed = (
  isCollapsed: boolean,
  dispatch: ContextDispatch,
) => {
  localStorage.setItem(SET_SUMMARIES_COLLAPSED, isCollapsed.toString());
  dispatch({
    type: SET_SUMMARIES_COLLAPSED,
    payload: isCollapsed,
  });
};

export const setAssetDetailsAssetId = (
  assetDetailsId: string | null,
  dispatch: ContextDispatch,
) => {
  dispatch({
    type: SET_ASSET_DETAILS_ID,
    payload: assetDetailsId,
  });
};
