import { Announcement, MenuHeader } from '../../connected';
import React, { FC } from 'react';
import { Layout } from 'antd';
import styles from './styles.module.less';

interface Props {
  children: React.ReactNode,
  menuHeaderChildren: JSX.Element,
  show?: boolean,
}

const SiteLayout: FC<Props> = ({ children, menuHeaderChildren, show }) => {

  if (!show) {
    return (
      <div className={styles.siteLayout}>
        {children}
      </div>
    );
  }

  return (
    <>
      <Layout>
        <Layout className={styles.siteLayout}>
          <Announcement />
          <MenuHeader>
            {menuHeaderChildren}
          </MenuHeader>
          {children}
        </Layout>
      </Layout>
    </>
  );
};
export default SiteLayout;
