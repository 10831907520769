import React, { createContext, useContext } from 'react';

export type FeatureStateType = {
  assetManagerId: string
};

const initialState: FeatureStateType = {
  assetManagerId: 'status_tracking',
};

export const FeatureContext = createContext({ state: initialState });

export const useFeature = (): [FeatureStateType] => {
  const { state } = useContext(FeatureContext);
  return [state];
};

export const FeatureProvider = ({ value, children }) => {
  return (
    <FeatureContext.Provider
      value={value}
    >
      {children}
    </FeatureContext.Provider>
  );
};
