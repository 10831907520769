import { AssetTypeDropdown, DateControls } from '../';
import {
  Button,
  Dropdown,
  InputRef,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  getAssetManagerFilters,
  getSelectedAssetType,
} from '../../../selectors';
import {
  setMultiSearchMode,
  setSearchTags,
  setSearchText,
  setTypeFilter,
} from '../../../actions';
import { DownOutlined } from '@ant-design/icons';
import { TagInput } from '../../atoms';
import { analytics } from '../../../services';
import { capitalize } from 'lodash';
import dayjs from 'dayjs';
import dayjsTimezone from 'dayjs/plugin/timezone';
import dayjsUtc from 'dayjs/plugin/utc';
import { getContextStores } from '../../../context';
import styles from './styles.module.less';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(dayjsTimezone);
dayjs.extend(dayjsUtc);

dayjs.updateLocale('en', {
  weekStart: 1,
});

const Filters = () => {
  /* Context */
  const contextStores = getContextStores();
  const {
    featureState,
    assetManagerDispatch,
  } = contextStores;
  const { assetManagerId } = featureState;

  const [needUpdate, setNeedUpdate] = useState<boolean>(false);
  const [displayedSearchText, setDisplayedSearchText] = useState<string>('');
  const textInput = useRef<InputRef>(null);
  const assetManagerFilters = getAssetManagerFilters(contextStores);
  const {
    typeFilter,
    searchTags,
    searchText,
    multiSearchModeFilter,
  } = assetManagerFilters;

  useEffect(() => {
    if (searchTags.length > 0) {
      textInput.current?.focus();
    }
  }, [textInput.current]);

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(
      displayedSearchText, assetManagerId, assetManagerDispatch,
    ), 200);
    if (!!displayedSearchText) analytics.trackEvent('Search Changed');
    return () => clearTimeout(timeOutId);
  }, [displayedSearchText]);

  useEffect(() => {
    if (searchText === '') {
      setDisplayedSearchText('');
    }
  }, [searchText]);

  // Auto select the first type if nothing is selected
  const currentType = getSelectedAssetType(contextStores);
  const { id } = currentType;

  useEffect(() => {
    if (needUpdate) {
      setNeedUpdate(false);
    }
  }, [needUpdate]);

  useEffect(() => {
    if (!typeFilter) {
      setTypeFilter(id, assetManagerId, assetManagerDispatch);
    }
  }, [typeFilter]);

  const selectAfter = <Dropdown menu={{
    items:[
      { key: 'and', label: 'And' },
      { key: 'or', label: 'Or' },
    ],
    onClick: (e) => {
      setMultiSearchMode(e.key as 'and' | 'or', assetManagerId, assetManagerDispatch);
    },
  }}>
    <Button className={styles.multipleSearchMode}>
      {capitalize(multiSearchModeFilter) }
      <DownOutlined />
    </Button>
  </Dropdown>;

  const renderTags = () => <TagInput
    searchTags={searchTags}
    searchText={displayedSearchText}
    addonAfter={selectAfter}
    onChangeText={(text) => {
      setDisplayedSearchText(text);
    }}
    onChangeTags={(v) => {
      setSearchTags(v, assetManagerId, assetManagerDispatch);
    }}
  />;

  return <div className={styles.container}>
    <div className={styles.filtersContainer}>
      <AssetTypeDropdown />
    </div>
    <div className={styles.searchContainer}>
      {renderTags()}
    </div>
    {assetManagerId === 'status_tracking' && <DateControls />}
  </div>;
};

export default Filters;