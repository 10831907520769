import {
  RESET_ALL_CHANGES,
  RESET_BLUEPRINT,
  RESET_CHANGES,
  SET_BLUEPRINT_SYNC,
  SET_BLUEPRINT_SYNC_PLAN,
  SET_BLUEPRINT_UPDATE,
  SET_CHANGES,
  SET_CHANGES_BY_KEY,
  SET_IS_CLONING,
  SET_IS_SYNCING,
  SET_IS_UPDATING,
} from '../actions/actionTypes';
import { ConfigurationStateType } from '../context';
import { ContextDispatchParam } from '../config/types';

export default (
  state: ConfigurationStateType,
  action: ContextDispatchParam,
): ConfigurationStateType => {
  switch (action.type) {
    case SET_CHANGES:
      return {
        ...state,
        changes: action.payload,
      };
    case RESET_CHANGES:
      return {
        ...state,
        changes: {
          ...state.changes,
          [action.payload]: state.blueprintUpdate?.[action.payload],
        } as ConfigurationStateType['changes'],
      };
    case RESET_ALL_CHANGES:
      return {
        ...state,
        changes: state.blueprintUpdate,
      };
    case SET_BLUEPRINT_UPDATE:
      return {
        ...state,
        blueprintUpdate: action.payload,
      };
    case SET_BLUEPRINT_SYNC:
      return {
        ...state,
        blueprintSync: action.payload,
      };
    case SET_BLUEPRINT_SYNC_PLAN:
      return {
        ...state,
        blueprintSyncPlan: action.payload,
      };
    case SET_CHANGES_BY_KEY: {
      const { blueprintUpdate } = action.payload;
      return {
        ...state,
        changes: {
          ...state.changes,
          [action.payload.key]: blueprintUpdate[action.payload.key],
        } as ConfigurationStateType['changes'],
      };
    }
    case RESET_BLUEPRINT:
      return {
        ...state,
        blueprintUpdate: null,
      };
    case SET_IS_UPDATING:
      return {
        ...state,
        isUpdating: action.payload,
      };
    case SET_IS_SYNCING:
      return {
        ...state,
        isSyncing: action.payload,
      };
    case SET_IS_CLONING:
      return {
        ...state,
        isCloning: action.payload,
      };
    default:
      return state;
  }
};