
import React, { FC } from 'react';
import {
  checkIsStatusUpdateEnabled,
  getSelectedAssetType,
} from '../../../../../selectors';
import { Asset } from '../../../../../config/types/assets';
import EditObservation from '../EditObservation';
import { ExpandingTimelineType } from '../../../../../hooks/useExpandingTimeline';
import { Field } from '@ynomia/core/dist/blueprint';
import FieldValues from '../../../../atoms/FieldValues';
import { ObservationIcon } from '../../../../atoms';
import { ObservationListItem } from '../../../../../config/types';
import { Tooltip } from 'antd';
import { getContextStores } from '../../../../../context';
import { getObservationFieldSchema } from '../../../../../utils/asset';
import styles from './styles.module.less';

interface Props {
  observation: ObservationListItem;
  expandedKey: string;
  expandController: ExpandingTimelineType;
  asset: Asset;
}

const ObservationItem: FC<Props> = ({ observation, expandController, expandedKey, asset }) => {
  const isOpened = expandController.expandedRowKeys.includes(expandedKey);

  const contextStores = getContextStores();
  const selectedAssetType = getSelectedAssetType(contextStores);
  const isStatusUpdateEnabled = checkIsStatusUpdateEnabled(contextStores);

  const { title, action, description, icon, tooltip, fontColor } = observation;
  const observationRaw = observation.observation_raw;
  const { fields, type } = observationRaw || {};

  const fieldSchema = getObservationFieldSchema(action, selectedAssetType.observations, type);

  const items = <FieldValues
    schema={fieldSchema as Field[]}
    values={fields || {}}
    asset={asset}
    display={{ gutter: [4, 0], labelColSpan: 8, valueColFlex: 'auto' }}
  />;

  const renderTimeLineLabel = () => {
    const { isAutoFill, canEditDate } = observation;
    const label = isAutoFill ? '' : observation.timestamp_human_readable;
    return label ? (
      <div style={{ display: 'inline-flex' }}>
        <div className={styles.descriptionText}>{label}</div>
        {canEditDate && <EditObservation observation={observation} />}
      </div>
    ) : (
      isStatusUpdateEnabled && <EditObservation observation={observation} />
    );
  };

  const labelStyles = fontColor ? styles.fadedLabelContainer : styles.statusLabelContainer;

  return (
    tooltip ? <Tooltip
      title={tooltip}
      placement="topLeft"
    >
      <div style={{ display: 'flex' }}>
        <div className={styles.statusIconContainer}>
          <ObservationIcon name={icon.name} type={icon.type} color="#999"/>
        </div>
        <div className={labelStyles}>
          {title}
        </div>
      </div>
      <div className={styles.descriptionText}>
        {description}
      </div>
    </Tooltip> : <>
      <div style={{ display: 'flex' }}>
        <div className={styles.statusIconContainer}>
          <ObservationIcon name={icon.name} type={icon.type} color={fontColor || ''}/>
        </div>
        <div
          className={action === 'NONE' ? labelStyles : styles.statusLinkContainer}
          onClick={()=> action !== 'NONE' && expandController.toggleRowExpanded(expandedKey)}
        >
          {title}
        </div>
      </div>
      <div className={styles.descriptionText}>
        {description}
      </div>
      <Tooltip
        title={tooltip ?? ''}
        placement="topRight"
      >
        {renderTimeLineLabel()}
      </Tooltip>
      {isOpened && <div style={{ paddingLeft: 8, paddingRight: 32 }}>
        {items}
      </div>}
    </>
  );
};

export default ObservationItem;
