import React, { createContext, useContext, useReducer } from 'react';
import { ContextDispatch } from '../config/types';
import DigitalTwinReducer from '../reducers/digitalTwin';

export type DigitalTwinStateType = {
  isTwinReady: boolean
  isTwinLoading: boolean
  lastSelectedLayer: string | undefined
  loadingPercentage: number
};

const initialState: DigitalTwinStateType = {
  isTwinReady: false,
  isTwinLoading: false,
  lastSelectedLayer: undefined,
  loadingPercentage: 0,
};

export const DigitalTwinContext = createContext({
  state: initialState,
  dispatch: (() => {}) as ContextDispatch,
});

export const useDigitalTwin = (): [DigitalTwinStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(DigitalTwinContext);
  return [state, dispatch];
};

export const DigitalTwinProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DigitalTwinReducer, initialState);

  return (
    <DigitalTwinContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </DigitalTwinContext.Provider>
  );
};
