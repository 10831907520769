import { getAssetManagerFilters, getAssetTypes } from '..';
import { getAssetsKeyedByStatus, isFilteredByDateFilter } from '../../utils';
import { getFilteredAssets, getFilteredAssetsContext } from '.';
import { RawObservationRecord } from '../../config/types';
import { createSelector } from 'reselect';
import { getSelectedAssetType } from '../selectedType';
import { roundValue } from '@ynomia/core/dist/utils';

const getFilteredAssetsForAssetCurrentStatusSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    } = assetManagerFilters;

    const filteredAssets = getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    });
    if (dateFilter?.length !== 2) return filteredAssets;
    return filteredAssets
      .filter((asset)=> {
        const obs = asset.observations?.find(({ to })=> {
          return to === asset.status;
        });
        const date = obs?.date;
        const dateFormat = date && new Date(date);
        return dateFormat && isFilteredByDateFilter(dateFormat, dateFilter);
      });
  },
);

const getFilteredAssetsForAssetStatusSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    } = assetManagerFilters;
    const filteredAssets = getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      observationTypeFilter,
      typeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      supplierFilter,
      multiSearchModeFilter,
    });
    return filteredAssets.sort((a, b) => String(a.label).localeCompare(String(b.label)));
  },
);

const getObservationStatusUpdateKeyedByStatusId = createSelector(
  [getAssetManagerFilters, getFilteredAssetsForAssetStatusSummary],
  (assetManagerFilters, assetsArray) => {
    const { dateFilter } = assetManagerFilters;
    const observationStatusUpdateKeyedByStatuses = new Map<string, Array<RawObservationRecord>>();

    if (assetsArray) {
      assetsArray.forEach((asset) => {
        const { observations } = asset;
        // Omit repeat observations within the same asset
        const uniqueStatusIds = new Set<string>();
        observations?.forEach((obs) => {
          const date = obs.date;
          const dateCheck = (dateFilter?.length !== 2)
              || (date && isFilteredByDateFilter(new Date(date), dateFilter));
          if (obs.to && !uniqueStatusIds.has(obs.to) && dateCheck) {
            uniqueStatusIds.add(obs.to);
            const record = observationStatusUpdateKeyedByStatuses.get(obs.to);
            if (record === undefined) {
              observationStatusUpdateKeyedByStatuses.set(obs.to, [obs]);
            } else {
              record.push(obs);
            }
          }
        });
      });
    }

    return observationStatusUpdateKeyedByStatuses;
  },
);

const getAssetsKeyedByStatusForCurrentAssetStatusSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForAssetCurrentStatusSummary],
  getAssetsKeyedByStatus,
);

export const getAssetStatusSummary = createSelector(
  [
    getAssetManagerFilters,
    getAssetsKeyedByStatusForCurrentAssetStatusSummary,
    getFilteredAssetsForAssetStatusSummary,
    getSelectedAssetType,
    getObservationStatusUpdateKeyedByStatusId,
  ],
  (
    assetManagerFilters,
    assetsKeyedByStatus,
    assetsArray,
    selectedAssetType,
    observationStatusUpdateKeyedByStatuses,
  ) => {
    const { typeFilter, dateFilter } = assetManagerFilters;

    if (!typeFilter || !assetsArray) {
      return [];
    }
    const { statuses } = selectedAssetType;
    const hasDateFilter = dateFilter?.length === 2;

    return statuses ? statuses.map((status) => {
      const { id, label, color } = status;
      const currentOccurrence = assetsKeyedByStatus.get(id)?.length || 0;
      const assetCount = assetsArray?.length;
      const defaultCumulativeOccurrence = hasDateFilter ? 0 : assetCount;
      const cumulativeOccurrence = status.default ?
        defaultCumulativeOccurrence : (observationStatusUpdateKeyedByStatuses.get(id)?.length || 0);
      const currentOccurrencePercentage = assetCount && roundValue(
        (currentOccurrence * 100) / assetCount,
        1,
      );
      const cumulativeOccurrencePercentage = assetCount && roundValue(
        (cumulativeOccurrence * 100) / assetCount,
        1,
      );
      return {
        id,
        label,
        color,
        currentOccurrence,
        currentOccurrencePercentage,
        cumulativeOccurrence,
        cumulativeOccurrencePercentage,
      };
    }) : [];
  },
);
