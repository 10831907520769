import React, { FC } from 'react';
import { DEFAULT_STATUS_COLOR } from '../../../config/constants';
import styles from './styles.module.less';

interface Props {
  color?: string | undefined
  isRowSelected: boolean
  style?: React.CSSProperties
}

const ColorIndicator: FC<Props> = ({ color, isRowSelected, style }) => {
  const iconColor = color || DEFAULT_STATUS_COLOR;
  return (
    <div
      className={styles.colorIndicator}
      style={{
        borderColor: iconColor,
        backgroundColor: isRowSelected ? iconColor : 'white',
        border: `2px solid ${iconColor}`,
        ...style,
      }}
    />
  );
};
export default ColorIndicator;
