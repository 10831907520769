import { AssetTableColumn } from '../config/types';
import { createSelector } from 'reselect';
import { getAssetManagerFilters } from './';
import map from './map';

export const getListViewByAssetManagerId = createSelector(
  [map.features],
  (features): Map<string, { [key: string]: Array<AssetTableColumn> }> => {
    const listViewByAssetManagerId: Map<string, { [key: string]: Array<AssetTableColumn> }>
      = new Map();

    if (features) {
      const featuresKeys = Object.keys(features);
      featuresKeys?.forEach((key) => {
        listViewByAssetManagerId.set(key, features[key]?.list_view);
      });
    }
    return listViewByAssetManagerId;
  },
);

export const getListViewColumns = createSelector(
  [
    map.assetManagerId,
    getListViewByAssetManagerId,
    getAssetManagerFilters,
  ], (
    assetManagerId,
    listViewByAssetManagerId,
    assetManagerFilters,
  ): Array<AssetTableColumn> => {

    const currentFeatureListViews = listViewByAssetManagerId.get(assetManagerId) || {};
    const { typeFilter } = assetManagerFilters;
    const listView = currentFeatureListViews[typeFilter];

    if (!listViewByAssetManagerId || !listView || !typeFilter) {
      return [
        {
          key: 'label',
          column_order: {
            full_view: 1,
            small_view: 1,
          },
          title: 'Name',
          filter_enabled: false,
          sort_enabled: true,
        },
        {
          key: 'unit_type',
          title: 'Unit Type',
          column_order: {
            full_view: 2,
            small_view: 2,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          key: 'supplier',
          title: 'Supplier',
          column_order: {
            full_view: 3,
            small_view: 3,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          title: 'Level',
          key: 'destination',
          column_order: {
            full_view: 4,
            small_view: 4,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          title: 'Load No.',
          key: 'load_number',
          column_order: {
            full_view: 5,
            small_view: 5,
          },
          filter_enabled: true,
          sort_enabled: true,
        },
        {
          title: 'Status',
          key: 'status',
          column_order: {
            full_view: 6,
            small_view: 6,
          },
          filter_enabled: false,
          sort_enabled: true,
        },
        {
          title: 'Achieved At',
          key: 'statusUpdatedDate',
          column_order: {
            full_view: 7,
            small_view: 7,
          },
          custom: 'formatDate',
          filter_enabled: true,
          sort_enabled: true,
        },
      ];
    }

    if (assetManagerId === 'status_tracking') {
      listView[0] = { ...listView[0], fixed: 'left' };
    }

    return listView;
  },
);

export const getColumnsKeyedById = createSelector(
  [getListViewColumns], (listViewColumns) => {
    return new Map(
      listViewColumns.map(column => [column.key, column]),
    ) as Map<string, AssetTableColumn>;
  },
);

export const getCustomColumns = createSelector(
  [getListViewColumns], (listViewColumns) => {
    return listViewColumns.filter(({ custom }) => custom);
  },
);