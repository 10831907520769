import { Asset, Layer, LayerChildren, LayerChildrenMap } from '../config/types';
import { getAssetManagerFilters, getAssetsArray, getBuildingTemplate } from '.';
import { layerSegmentedPickerHelper, orderLayers } from '../utils';
import { SiteTemplate } from '@ynomia/core/dist/blueprint';
import { createSelector } from 'reselect';
import map from './map';

/**
 * Returns layers from the keyed state collection as a non-keyed array.
 */
export const getLayersArray = createSelector(
  [map.layersKeyedById],
  (layersKeyedById) => {
    return [...layersKeyedById.values()] as Array<Layer>;
  });

/**
 * Returns "primary" layers as an Array. Primary layers represent a space where beacon deployment
 * and other high level tracking information is conveyed, EG typically a "Floor Level".
 */
export const getPrimaryLayers = createSelector([getLayersArray], layersArray =>
  layersArray.filter(layer => layer.isPrimary),
);

/**
 * Returns "primary" layer ids as an array.
 */
export const getPrimaryLayerIds = createSelector([getPrimaryLayers], (primaryLayers) => {
  return primaryLayers.map(layer => layer.id);
});

export const getLayersKeyedByParentID = createSelector(
  [getLayersArray],
  (layersArray) => {
    const parentIDsArray = orderLayers(layersArray)
      .map(({ parentID }) => parentID).flat() as Array<string>;
    const layersKeyedByParentID = new Map<string, Layer[]>();

    parentIDsArray.forEach((id) => {
      const layersFilteredByParentID = layersArray.filter((layer) => {
        return layer?.parentID === id;
      });
      layersKeyedByParentID.set(`${id}`, layersFilteredByParentID);
    });

    return layersKeyedByParentID as Map<string, Layer[]>;
  },
);

export const getRootLayersArray = createSelector(
  [getLayersKeyedByParentID, map.templates],
  (layersKeyedByParentID, templates) => {
    if (!layersKeyedByParentID || !templates) {
      return [];
    }
    const templateKeyedBy = new Map(templates.map(template => [template.id, template]));
    const layers = layersKeyedByParentID.get('null')?.filter(
      ({ template }) => {
        const layerTemplate = (template && templateKeyedBy.get(template)) as SiteTemplate;
        return layerTemplate?.destination;
      },
    ) as Array<Layer> || [];

    return orderLayers(layers);
  },
);

export const getLayersKeyedByAncestorId = createSelector(
  [map.layersKeyedById],
  (layersKeyedById):  Map<string, Array<Layer>> => {
    const layersKeyedByAncestorId: Map<string, Array<Layer>> = new Map();

    const getAllAncestors = (node, ancestors: Array<string> = []) => {
      if (node.parentID) {
        const parent = layersKeyedById.get(node.parentID);
        if (parent) {
          ancestors.push(parent.id);
          getAllAncestors(parent, ancestors);
        }
      }
      return ancestors;
    };

    const nodes = [...layersKeyedById.values()];
    nodes.forEach((node) => {
      // Get all ancestor IDs
      const ancestorIDs = getAllAncestors(node);

      // Add the current node to each ancestor's layer array
      ancestorIDs.forEach((ancestorID) => {
        const list = layersKeyedByAncestorId.get(ancestorID) || [];
        list.push(node);
        layersKeyedByAncestorId.set(ancestorID, list);
      });
    });

    return layersKeyedByAncestorId;
  },
);

export const getAssetsKeyedByLayers = createSelector(
  [getAssetsArray, getPrimaryLayerIds],
  (assetsArray, primaryLayerIds) => {
    const assetKeyedByLayers = new Map();

    if (primaryLayerIds.length && assetsArray.length) {
      primaryLayerIds.forEach((id) => {
        const assetsFilteredByLayerId = assetsArray.filter(
          ({ destination }) => destination?.layerID === id,
        );
        assetKeyedByLayers.set(id, assetsFilteredByLayerId);
      });
    }

    return assetKeyedByLayers as Map<string, Array<Asset>>;
  },
);

export const getLayerChildrenMap = createSelector(
  [map.layersKeyedById],
  (layersKeyedById): LayerChildrenMap => {
    const layers = [...layersKeyedById.values()];
    const findChildren = (layer: Layer): Array<LayerChildren> => {
      const children = layers.filter(l => l.parentID === layer.id);
      return children.map(l => ({
        layerId: l.id,
        children: findChildren(l),
      }));
    };
    return layers.reduce((processedLayers, layer) => ({
      ...processedLayers,
      [layer.id]: findChildren(layer),
    }), {});
  },
);

export const getRootLayers = createSelector(
  [getLayersArray],
  layersArray => layersArray.filter(l => l.isRoot),
);

export const getLayerSegmentedPickerColumns = createSelector(
  [map.layersKeyedById, map.templates, getRootLayers, getLayerChildrenMap],
  layerSegmentedPickerHelper.getLayerSegmentedPickerColumns,
);

export const getLayers = createSelector(
  [
    getAssetManagerFilters,
    getLayersKeyedByParentID,
    getBuildingTemplate,
    getRootLayersArray,
  ],
  (
    assetManagerFilters,
    layersKeyedByParentId,
    buildingTemplates,
    rootLayersArray,
  ) => {
    const { openedLayerId } = assetManagerFilters;
    if (!buildingTemplates || !openedLayerId) {
      const defaultRootLayer = rootLayersArray && rootLayersArray[0];
      return orderLayers(layersKeyedByParentId.get(defaultRootLayer?.id)!) || [];
    }
    return orderLayers(layersKeyedByParentId.get(openedLayerId)!) || [];
  },
);
