import { Asset, ContextDispatch } from '../config/types';
import React, { createContext, useContext, useReducer } from 'react';
import AssetReducer from '../reducers/assets';

export type AssetStateType = {
  assetsKeyedById: Map<string, Asset>
  isFetchingAssets: boolean
  isUpdatingObservation: boolean
  lastFetchFailed: boolean
  lastFetchStartTime: Date | null
  isUpdatingBulkObservation: boolean
  initialAssetFetchComplete: boolean
};

const initialState: AssetStateType = {
  assetsKeyedById: new Map(),
  isFetchingAssets: false,
  lastFetchFailed: false,
  initialAssetFetchComplete: false,
  lastFetchStartTime: null,
  isUpdatingObservation: false,
  isUpdatingBulkObservation: false,
};

export const AssetContext = createContext({
  state: initialState,
  dispatch: (() => {}) as ContextDispatch,
});

export const useAsset = (): [AssetStateType, ContextDispatch] => {
  const { state, dispatch } = useContext(AssetContext);
  return [state, dispatch];
};

export const AssetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AssetReducer, initialState);

  return (
    <AssetContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
      }}
    >
      {children}
    </AssetContext.Provider>
  );
};
